
    import debounce from 'lodash/debounce'
    import FixedHeader from 'vue-fixed-header'

    const SEARCH_THRESHOLD = 300;

    export default {
        components: {
            FixedHeader
        },
        data() {
            return {
                lead: {
                    street_address: null,
                    city: null,
                    neighborhood: null,
                    state: null,
                    zip: null,
                    latitude: null,
                    longitude: null,
                    gclid: null,
                    msclkid: null,
                    google_geocode: null,
                },
                visibleResults: null,
                autocompleteService: null,
                placeService: null,
                debouncedSearch: null,
                searchResults: [],
                searchBox: [],
                error: null,
                address: null,
                show_bbb: null,
                loading_google: false
            }
        },
        computed: {
            fixedHeaderScrollThreshold() {
                if (!process.client) return null;
                return window.innerWidth >= 992 ? 550 : 300;
            }
        },
        mounted() {
            this.setTrackingIDs();
            this.initAutocomplete();
            this.storeUtmVars();
            this.$nuxt.$once('app:ready', () => {
                this.initBbb()
                this.trackVisitor()
            });
        },
        methods: {
            initBbb() {
                this.show_bbb = true;
            },
            initAutocomplete() {
                if (!window.google) {
                    this.$nuxt.$once('googleMapApi:init', this.initAutocomplete);
                    return;
                }

                this.autocompleteService = new google.maps.places.AutocompleteService();
                this.placeService = new google.maps.places.PlacesService(this.$refs['places-holder']);
                this.error = false;
            },

            searchAddress(event) {
            	if(!this.autocompleteService || event.key === 'Enter') return;
                if(this.debouncedSearch) {
                    this.debouncedSearch.cancel();
                }
                this.debouncedSearch = debounce(() => {
                    if(!this.address) return;

	                this.error = null;

                    this.autocompleteService.getPlacePredictions({
                        input: this.address,
                        types: ['address'],
                        componentRestrictions: {country: "us"}
                    }, result => {
                        if(!result || !result.length) return;
                        this.searchResults = result.filter(item => item.terms[0].value.match(/^[0-9]+/));
                    })
                }, SEARCH_THRESHOLD);
                this.debouncedSearch();
            },

            checkAddress() {
	            if(!this.autocompleteService) return;
	            if(this.debouncedSearch) {
		            this.debouncedSearch.cancel();
	            }
                this.autocompleteService.getPlacePredictions({
                    input: this.address,
                    types: ['address'],
                    componentRestrictions: {country: "us"}
                }, result => {
                    if(result && result.length) {
                        result = result.filter(item => item.terms[0].value.match(/^[0-9]+/));
                    }
                    if(!result || !result.length) {
                        this.error = 'Sorry, we couldn\'t find that address. Try filling without zip or unit number.';
                    } else {
                        this.address = result[0].description;
                        this.updateAddressComponents(result[0]);
                    }
                })
            },

            updateAddressComponents(place) {
                this.visibleResults = null;
                this.address = place.description;

                // Manually set loading state to avoid double drafts
                this.loading_google = true;

                this.placeService.getDetails({
                    placeId: place.place_id
                }, async result => {
                    let {
                        photos,
                        adr_address,
                        icon,
                        icon_background_color,
                        icon_mask_base_uri,
                        ...clean_place
                    } = result;

                    this.lead.google_geocode = {status: 'OK', results: [{...clean_place}]};

                    this.lead.latitude = result.geometry.location.lat();
                    this.lead.longitude = result.geometry.location.lng();

                    let streetAddress = [];

                    result.address_components.reverse().forEach(item => {
                        if (item.types.indexOf('locality') !== -1) this.lead.city = item.short_name;
                        if (item.types.indexOf('sublocality') !== -1 && !this.lead.city) this.lead.city = item.short_name;
                        if (item.types.indexOf('neighborhood') !== -1) this.lead.neighborhood = item.short_name;
                        if (item.types.indexOf('administrative_area_level_2') !== -1) this.lead.google_county = item.short_name;
                        if (item.types.indexOf('administrative_area_level_1') !== -1) this.lead.state = item.short_name;
                        if (item.types.indexOf('postal_code') !== -1) this.lead.zip = item.short_name;
                        if (item.types.indexOf('street_number') !== -1) streetAddress.unshift(item.short_name);
                        if (item.types.indexOf('route') !== -1) streetAddress.unshift(item.short_name);
                    });

                    this.lead.street_address = streetAddress.join(' ');

                    if(!this.lead.city && this.lead.neighborhood) this.lead.city = this.lead.neighborhood;

                    this.$store.commit('lead/clear', this.lead);
                    this.$store.commit('lead/update', this.lead);
                    this.$nextTick(() => {
                        this.submit()
                    });
                });
            },
            setTrackingIDs() {
                if(this.$route.query.gclid) this.lead.gclid = this.$route.query.gclid;
                if(this.$route.query.msclkid) this.lead.msclkid = this.$route.query.msclkid;
            },
            async submit() {
                if (this.lead.street_address && this.lead.city && this.lead.state && this.lead.zip) {
                    this.$store.commit('lead/update', this.lead);
                    await this.$store.dispatch('lead/post');
                    this.afterSend();
                    this.$router.push('/address/verify');
                } else if (!this.lead.street_address || !this.lead.city || !this.lead.state || !this.lead.zip || !this.address) {
                    this.error = 'Sorry, we couldn\'t find that address. Try filling without zip or unit number.';
                }

                // Remove manual loading state
                this.loading_google = false;
            },
            closeResultsTop() {
                if(this.visibleResults === 1) {
                    this.visibleResults = null;
                }
            },
            closeResultsBottom() {
                if(this.visibleResults === 2) {
                    this.visibleResults = null;
                }
            },
            afterSend() {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'address-submission',
                    gclid: this.$store.state.lead.gclid,
                    msclkid: this.$store.state.lead.msclkid
                });
            },
            storeUtmVars() {
                const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
                utmFields.map(utmField => {
                    if (this.$route.query[utmField]) {
                        this.lead[utmField] = this.$route.query[utmField];
                    }
                });
                this.$store.commit('lead/update', this.lead);
            },
            loadResources() {
                this.$nuxt.$emit('googleResources:load');
            },

            trackVisitor() {
                let data = {}
                if (window?.location?.search?.slice(1)) {
                    var pairs = window.location.search.slice(1).split('&');

                    var result = {};
                    pairs.forEach(function(pair) {
                        pair = pair.split('=');
                        result[pair[0]] = decodeURIComponent(pair[1] || '');
                    });

                    data = JSON.parse(JSON.stringify(result));
                }
                try {
                    window.navigator.sendBeacon(this.$config.webhooks_url, JSON.stringify({
                        ...data,
                        account_id: this.$config.cur_site_config.account_id
                    }))
                } catch (e) {}
            }
        }
    }
